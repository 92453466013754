<template>
  <div class="cityA2e">
    <el-container>
      <el-header height="100%">
          <vheadere class="hidden-sm-and-down"></vheadere>
          <ivheaderae class="hidden-md-and-up"></ivheaderae>
          <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>INDUSTRIES</p>
             </div>
           </div>
      </el-header>
      <el-main>
        <el-row type="flex" justify="center" class="isab">
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <div class="w clearfloat food-list-hook isab-mib">
              <div class="ec-top-foot">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item > <router-link to="/EN">Home</router-link></el-breadcrumb-item>
                  <el-breadcrumb-item>Industries</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <a href="###" name="pill"></a>
              <div class="bgb-bss">
                <img src="../../assets/images/en/A/A2-bgb.png" class="bgb-i hidden-sm-and-down" alt>
                <img src="../../assets/images/en/A/bgb-ia.png" class="bgi-is hidden-md-and-up" alt>
              </div>
              <div class="mi">
                <el-row type="flex" justify="center" class="cards">
                  <el-col :xs="24" :sm="24" :md="12" class="card" v-for="(item, index) in cityb" :key="index">
                    <div class="card-af">
                      <el-col :xs="24" :sm="24" :md="24" >
                        <div class="card-icon">
                          <img :src="item.IMAGE" alt="">
                          <div class="icon-cn-txt" v-html="item.TITLE"></div>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="24">
                        <div class="card-txt">
                           {{item.SHORTCONTENT}}
                        </div>
                      </el-col>
                    </div>
                  </el-col>
                </el-row>
                <div class="idvo" v-for="item in places" :key="item">
                  <img width="100%" :src="item" alt>
                </div>
              </div>
              <a name="stra"></a>
              <div class="bgb-bsz">
                <img src="../../assets/images/en/A/A2-bgh.png" class="bgb-i hidden-sm-and-down" alt>
                <img src="../../assets/images/en/A/bgb-ib.png" class="bgi-i hidden-md-and-up" alt>
              </div>
              <div class="mis">
                <div class="main-x" v-for="(item, index) in cityc" :key="index">
                  <div class="main-i">
                    <div class="main-i-i">
                      <div class="main-ttt">0{{index+1}}</div>
                      <div class="main-l">
                        <img class="mian-ss" :src="item.IMAGE" alt>
                        <h3 v-html="item.ONE"></h3>
                      </div>
                    </div>
                    <div class="main-r"> <div class="sew"> {{item.TWO}} </div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ss">
              <div class="w">
                <a name="futu"></a>
                <div class="bgb-bs">
                  <img src="../../assets/images/en/A/A2-bgi.png" class="bgb-i hidden-sm-and-down" alt>
                  <img src="../../assets/images/en/A/bgb-ii.png" class="bgi-i hidden-md-and-up" alt>
                </div>
                <!-- <div class="foot miss">
                    <div class="foot-i" v-for="(item, index) in cityd" :key="index">
                      <div class="foot-i-a">
                        <div class="foot-x">0{{index+1}}</div>
                        <div class="foot-t">
                          <div class="foot-m">{{ item.TITLE }}</div>
                        </div>
                      </div>
                      <div class="foot-txt"><div class="foot-txt-t">{{ item.SHORTCONTENT }}</div></div>
                    </div>
                </div> -->
                <el-row>
                <div class="reform-card miss w">
                  <el-col :xs="24" :sm="24" :md="8" v-for="(item,index) in cityd" :key="index">
                    <div class="card-box">
                      <div class="title-box">
                        <div class="card-num">{{index > 9?index:'0' + (index+1)}}</div>
                        <div class="card-title">
                          <div>
                            <div class="title-cn">
                              {{ item.TITLE }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-txt">
                        {{ item.SHORTCONTENT }}
                      </div>
                    </div>
                  </el-col>
                </div>
              </el-row>
              </div>
              <div class="sin">
                <el-popover
                  placement="right"
                  title="Pillar Industries"
                  width="150"
                  height='20'
                  trigger="hover">
                  <a href="#pill" slot="reference"></a>
                </el-popover>
                <el-popover
                  placement="left"
                  title="Strategic Industries"
                  width="150"
                  trigger="hover">
                  <a slot="reference" href="#stra"></a>
                </el-popover>
                <el-popover
                  placement="left"
                  title="Future Industries"
                  width="150"
                  trigger="hover">
                  <a slot="reference" href="#futu"></a>
                </el-popover>
              </div>
              <gotop></gotop>
            </div>
          </el-col>
        </el-row>
      </el-main>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Gotop from '../../components/gotop'
import { szcityba, szcitybb, szcitybc, szcitybd } from '../../api-en'
export default {
  data () {
    return {
      citya: {},
      cityb: {},
      cityc: {},
      cityd: {},
      listHeight: [],
      places: {}
    }
  },
  created () {
    this._calculateHeight()
  },
  mounted () {
    szcityba().then(res => {
      console.log(1)
      console.log(res.data)
      this.citya = res.data
      this.places = res.data[0].ONE
      // console.log(this.citya)
      let Arr = this.places.split('</p>')
      // eslint-disable-next-line no-array-constructor
      let Src = new Array()
      for (var y = 0; y < Arr.length; y++) {
        if (Arr[y].indexOf('src') !== -1) {
          Src.push(Arr[y].match(/src="(\S*)"/)[1])
        }
      }
      this.places = Src
      console.log(this.places)
    })
    szcitybb().then(res => {
      console.log(5)
      console.log(res.data)
      this.cityb = res.data
    })
    szcitybc().then(res => {
      console.log(3)
      console.log(res.data)
      this.cityc = res.data
      // console.log(this.cityc)
    })
    szcitybd().then(res => {
      console.log(4)
      console.log(res.data)
      this.cityd = res.data
      console.log(this.cityd)
    })
  },
  methods: {
    _calculateHeight () {
      // let foodList = this.$ref.foodsWarper
    }
  },
  components: {
    Gotop
  }
}
</script>

<style src='../../assets/css/CityA2.css' ></style>

<style lang="less">
.el-main {
    margin-top: 0;
}
.el-popover {
  padding: 0;
}
.el-popover .el-popover__title {
  height: 20px;
  font-size: 0.3rem;
  text-align: center;
  align-items: center;
  line-height: 34px;
  color: #6a6969;
}
.cityA2e{
  .miss .foot-i .foot-txt {
    padding-bottom: 0.3rem;
    text-indent: 0;
  }
  .mis .main-x .main-i .main-r {
    text-indent: 0;
    text-align: justify;
  }
  .el-row {
    flex-wrap: wrap;
  }
  .mis .main-x .main-i .main-l  h3 > p {
    white-space: initial;
  }
  .mis .main-x:nth-child(3) .main-i .main-l {
    padding-right: 18px;
  }
  .miss .foot-i .foot-i-a .foot-t .foot-m {
    font-size: 0.4rem;
    /* position: absolute; */
    bottom: 0;
    left: 1.3rem;
    font-weight: 700;
  }
  @media screen and (max-width: 769px) {
    .main-i-i .main-l p {
      display: inline-block;
    }
    .mis .main-x:nth-child(3) .main-i .main-l {
      padding-right: 8px;
    }
    .miss .card-box .card-txt {
      margin-top: 0;
      padding: 0 0.4rem 0.8rem 0.4rem;
      text-align: justify;
    }
    .miss .card-box .card-title .title-cn {
      word-break: break-all;
      bottom: 23%;
      left: 22%;
    }
  }
}
</style>
